.profile-info{
    position: relative;

}
.upload-input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.ps.ps--active-x .dropdown-toggle::after{
content: unset;
}
.profile-dp{
    width: 180px;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden; 
}
.profile-dp img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.categories-name {
    color: transparent;
    padding-bottom: 16px;
}
.wrapper-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.categories-container {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
}

.cat-item {
    position: relative;
    img {
        max-width: 30px;
    }
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        opacity: 0;
    }
    .item {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 5px 12px;
        max-height: 36px;
        border-radius: 100px;

        span {
            color: transparent;
            display: inline-block;
        }
    }
    input[type='checkbox']:checked + .item {
        
        span {
            color: transparent;
        }
    }
}
.categories-name {
    color:black;
    padding-bottom: 16px;
}

/* .not-selectable {
    .categories-name {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $neutral-color-n-40;
        padding-bottom: 8px;
    }
    .item {
        span {
            font-weight: 500;
            color: $neutral-color-n-10;
        }
        background-color: #e5f2ff;
        border-color: #0080ff;
    }
} */
.cart-item{
    position: relative;

}
.cat-item input{cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;}
.item{align-items: center;
    border: 1px solid #b3b3b3;
    border-radius: 100px;
    -webkit-column-gap: 8px;
    column-gap: 8px;
    display: flex;
    max-height: 36px;
    padding: 5px 12px}
.item img{width: 20px;aspect-ratio: 1;}
.cat-item input[type=checkbox]:checked+.item{
    background-color: #e5f2ff;
    border-color: #0080ff;
}
.dropdown-toggle::after{content: none;}
.dlabnav .header-info2 i{margin-left: 8px;}